.register--container {
    display: grid;
}

.register--body {
    place-self: center;
    display: grid;
    width: 70%;
}

.register--form {
    margin-top: 40px;
    place-self: center;
    display: grid;
    grid-auto-flow: row;
    row-gap: 20px;
    column-gap: 30px;
    place-items: start center;
    grid-template-columns: 30px 1fr;
}

.form--validation-circle {
    place-self: center;
    height: 30px;
    width: 30px;
    background-color: rgb(218, 200, 221);
    border-radius: 30px;
    box-shadow: 1px 1px 1px rgba(120, 120, 120, 0.2);
}

.register--form > input {
    border: none;
    box-shadow: 0px 0px 5px rgba(150, 150, 150, 0.4);
    border-radius: 10px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    color: rgb(70, 70, 70);
    height: 40px;
    width: 350px;
}

.register--status {
    height: 100px;
    width: 90%;
    display: grid;
    place-self: center;
    place-content: center;
    place-items: center;
    text-align: center;
    font-size: 14px;
    color: rgb(80, 80, 80);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.register--submit {
    display: grid;
    place-items: center;
}

.register--submit-btn {
    place-self: center;
    border: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 16px;
    text-shadow: 1px 1px 1px rgba(120, 120, 120, 0.2);
    background-color: rgb(166, 138, 206);
    color: white;
    width: 120px;
    height: 45px;
    border-radius: 15px;
    box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
}

@media (max-width: 768px) {

    .register--body {
        width: 300px;
    }

    .register--form {
        margin-top: 0px;
        row-gap: 20px;
        column-gap: 20px;
        place-items: start center;
        grid-template-columns: 20px 1fr;
    }

    .form--validation-circle {
        height: 20px;
        width: 20px;
        border-radius: 20px;
    }

    .register--form > input {
        border-radius: 10px;
        font-size: 12px;
        color: rgb(70, 70, 70);
        height: 30px;
        width: 200px;
    }

    .register--status {
        height: 80px;
        font-size: 12px;
        color: rgb(80, 80, 80);
    }

    .register--submit-btn {
        width: 100px;
        height: 35px;
        border-radius: 15px;
        font-size: 13px;
    }

}