.div-displayer--body {
    width: 70%;
    display: grid;
    grid-auto-flow: row;
    row-gap: 15px;
}

.div-displayer--element {
    display: grid;
    grid-auto-flow: row;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.div-displayer--element-title {
    position: relative;
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    place-items: center;
    text-align: center;
    height: 80px;
    font-size: 14px;
    font-weight: bold;
    color: rgb(100, 100, 100);
    text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
    background-color: transparent;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    border-color: rgb(240, 240, 240);
    box-shadow: 4px 4px 10px rgba(120, 120, 120, 0.2);
    overflow: hidden;
    width: 100%;
    padding: 0px;
}

.element-title--free {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.element-title--text {
    display: grid;
    grid-auto-flow: column;
}

.element-title--btn {
    display: grid;
    place-items: center;
    width: 60px;
    height: 60px;
    border: none;
    background-color: transparent;
}

.element-title--btn-down {
    width: 0px;
    height: 0px;
    border-top-width: 12px;
    border-left-width: 12px;
    border-right-width: 12px;
    border-top-color: rgb(120, 120, 120);
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-style: solid;
    position: relative;
}

.element-title--btn-up {
    width: 0px;
    height: 0px;
    border-bottom-width: 12px;
    border-left-width: 12px;
    border-right-width: 12px;
    border-bottom-color: rgb(120, 120, 120);
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-style: solid;
    position: relative;
}


.div-displayer--element-text {
    place-self: center;
    padding: 20px;
    place-items: center;
    text-align: justify;
    font-size: 14px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    transition: 0.2s;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(240, 240, 240);
    margin-top: 15px;
    width: 90%;
    color: rgb(80, 80, 80);
    line-height: 1.8;
    box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
}

.div-displayer--element-text:hover {
    border-color: rgb(200, 200, 200);
}

@media (max-width: 768px) {
    .div-displayer--body {
        width: 90%;
        row-gap: 10px;
    }

    .div-displayer--element-title {
        grid-template-columns: 45px 1fr 45px;
        height: 60px;
        font-size: 11px;
        border-radius: 8px;
        box-shadow: 2px 2px 5px rgba(120, 120, 120, 0.2);
        text-shadow: none;
    }

    .div-displayer--element-text {
        font-size: 12px;
        border-width: 1px;
        width: 85%;
        margin-top: 10px;
        border-radius: 10px;
        line-height: 1.6;
    }

    .element-title--btn {
        width: 45px;
        height: 45px;
    }

    .element-title--btn-down {
        border-top-width: 8px;
        border-left-width: 8px;
        border-right-width: 8px;
    }

    .element-title--btn-up {
        border-bottom-width: 8px;
        border-left-width: 8px;
        border-right-width: 8px;
    }
}