@font-face {
  font-family: 'debussy';
  src: url(./fonts/debussy.ttf) format('woff'),
  url(./fonts/debussy.ttf) format('truetype');
}

body {
  margin: 0;
  -webkit-tap-highlight-color: transparent;
}

.test-button {
  margin-top: 200px;
  display: grid;
  position: absolute;
  place-self: start start;
  place-content: center;
  z-index: 2;
  border: none;
  width: 100px;
  height: 100px;
  background-color: rgb(229, 217, 247);
  color: #919191;
  font-size: 14px;
  font-family: monospace;
  border-radius: 20px;
}

.app--container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  width: 100%;
  height: 100%;
}

.page--container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 140px 1fr;
  margin-bottom: 30px;
}

.nav-bar {
  display: grid;
  border: none;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 80px;
  place-items: center;
  grid-auto-flow: column;
  background: linear-gradient(270deg, rgb(138, 182, 243), rgb(110, 207, 198), rgb(166, 138, 206)); /* #dac8dd */
  background-size: 300% 300%;
  -webkit-animation: GradientScroll 30s ease infinite;
  -moz-animation: GradientScroll 30s ease infinite;
  animation: GradientScroll 30s ease infinite;
}

@-webkit-keyframes GradientScroll {
  0%{background-position:14% 0%}
  50%{background-position:87% 100%}
  100%{background-position:14% 0%}
}
@-moz-keyframes GradientScroll {
  0%{background-position:14% 0%}
  50%{background-position:87% 100%}
  100%{background-position:14% 0%}
}
@keyframes GradientScroll {
  0%{background-position:14% 0%}
  50%{background-position:87% 100%}
  100%{background-position:14% 0%}
}

.nav--title {
  color: aliceblue;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  padding-top: 0px;
}

.nav--title > img {
  width: 210px;
  height: 85px;
  object-fit: cover;
}

.nav--title > img:active {
  transition: 0.1s ease-in-out;
  width: 189px;
  height: 78px;
}

.nav--buttons {
  display: grid;
  overflow: hidden;
  grid-auto-flow: column;
  place-self: center end;
}

.nav--button {
  border-style: none;
  background-color: transparent;
  font-family: Arial;
  font-size: 12px;
  color: aliceblue;
  width: 100px;
  height: 70px;
  transition: 0.2s;
  display: grid;
  place-items: center;
}

.nav--button > img {
  width: 35px;
  height: 35px;
  place-self: center;
}

.nav--button:hover {
  font-size: 14px;
}

.nav--button:active > img {
  transition: 0.1s ease;
  width: 31px;
  height: 31px;
}

.dark--title {
  color: rgb(80, 80, 80);
}

.nav--login {
  display: grid;
  grid-auto-flow: column;
}

.nav--login-btn {
  place-self: center;
  background-color: transparent;
  border-radius: 15px;
  border: 3px solid aliceblue;
  padding: 10px;
  color: aliceblue;
  font-family: Arial;
  font-weight: bold;
  width: 100px;
  height: 40px;
  transition: 0.2s;
}

.nav--login-btn:hover {
  background-color: aliceblue;
  color: rgb(166, 138, 206);
}

.cart-btn-light {
  position: absolute;
  z-index: 2;
  background-color: none;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  transition: 0.2s;
  place-self: start end;
}

.nav--user-info {
  place-self: center;
  overflow: hidden;
}

.nav--user-info > img {
  width: 45px;
  height: 45px;
}

.div--ribbon {
  display: grid;
  grid-auto-flow: column;
  text-align: center;
  place-content: center;
  place-items: center;
  vertical-align: middle;
  font-family: debussy;
  font-size: 54px;
  font-weight: normal;
  background-color: rgb(166, 138, 206);
  color: white;
  text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.3);
}

.div--error {
  text-align: center;
  place-self: start center;
  color: #555555;
  font-family: monospace;
  font-size: 20px;
}

.div--error-sign {
  color: #C38EB4;
  font-size: 60px;
  margin: 8px;
}

.div--not-authorized {
  height: 200px;
  display: grid;
  place-items: center;
  color: #555555;
  font-family: monospace;
  font-size: 20px;
  padding: 20px 5px 20px 5px;
  text-align: center;
}

.my-profile--event-date--reschedule {
  display: inline-flex;
  border-radius: 20px;
  background-color: #ffc107;
  background-image: url("../public/refresh.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  width: 40px;
  height: 40px;
  font-weight: bold;
  box-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2);
  border: none;
}

.small-circle {
  border-radius: 15px;
  background-size: 12px;
  width: 25px;
  height: 25px;
}

.profile-pic-2 {
  width: 75px;
  height: 75px;
}

.round-border {
  border-radius: 50%;
}

.highlight--text-1 {
  place-content: center;
  place-items: center;
  font-weight: bold;
  border: none;
  border-radius: 15px;
  padding: 1px 8px 1px 8px;
  background-color: rgb(229, 217, 247);
}

.text-align--center {
  text-align: center;
}

@keyframes fadeInUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in-up {
  animation: 0.75s both fadeInUp;
}

@keyframes expand {
  from {
    padding: 0px;
    height: 0px;
    
  }
  to {
    padding: 20px;
    height: auto;
    
  }
}

.expand {
  animation: 0.3s both expand;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation-delay: 0.3;
  animation: 0.5s both fadeIn;
}

.fade-in-0 {
  animation: 0.5s both 0.1s fadeIn;
}

.fade-in-1 {
  animation: 0.5s both 0.15s fadeIn;
}

.fade-in-2 {
  animation: 0.5s both 0.2s fadeIn;
}

.fade-in-3 {
  animation: 0.5s both 0.25s fadeIn;
}

.fade-in-4 {
  animation: 0.5s both 0.3s fadeIn;
}

.fade-in-5 {
  animation: 0.5s both 0.35s fadeIn;
}

.fade-in-6 {
  animation: 0.5s both 0.4s fadeIn;
}

.fade-in-7 {
  animation: 0.5s both 0.45s fadeIn;
}

.fade-in-8 {
  animation: 0.5s both 0.5s fadeIn;
}

.fade-in-9 {
  animation: 0.5s both 0.55s fadeIn;
}

.fade-in-12 {
  animation: 0.5s both 0.7s fadeIn;
}

.fade-in-18 {
  animation: 0.5s both 1s fadeIn;
}

@keyframes SlideIn {
  0% {
    left: -100px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}

@keyframes SlideIn {
  0%, 20% {
    left: -60px;
    opacity: 0;
  }
  100% {
    left: 0px;
    opacity: 1;
  }
}

.slide-in-1 {
  animation: 0.5s both 0.05s SlideIn ;
}

.slide-in-2 {
  animation: 0.5s both 0.15s SlideIn ;
}

.slide-in-3 {
  animation: 0.5s both 0.25s SlideIn ;
}

.slide-in-4 {
  animation: 0.5s both 0.35s SlideIn ;
}

.slide-in-5 {
  animation: 0.5s both 0.45s SlideIn ;
}

.slide-in-6 {
  animation: 0.5s both 0.55s SlideIn ;
}

.slide-in-7 {
  animation: 0.5s both 0.65s SlideIn ;
}

.slide-in-8 {
  animation: 0.5s both 0.75s SlideIn ;
}

.slide-in-9 {
  animation: 0.5s both 0.85s SlideIn ;
}

.slide-in-10 {
  animation: 0.5s both 0.95s SlideIn ;
}



@media (max-width: 768px) {
  .app--container {
    width: 100dvw;
    height: 100dvh;
  }

  .nav-bar {
    display: grid;
    grid-template-columns: 100px 1fr;
    width: 100dvw;
  }

  .nav--title > img {
    width: 100px;
    height: 100px;
    place-self: center;
  }

  .nav--title > img:active {
    transition: 0.1s ease;
    width: 90px;
    height: 90px;
  }

  .nav--buttons {
    display: grid;
    grid-auto-flow: column;
    place-self: center end;
  }

  .nav--button {
    width: 60px;
    height: 60px;
    display: grid;
    place-items: center;
    font-size: 10px;
    overflow: hidden;
  }

  .nav--button > img {
    width: 28px;
    height: 28px;
    place-self: center;
  }

  .nav--button:hover {
    font-size: 12px;
  }

  .nav--button:active > img {
    transition: 0.1s ease;
    width: 25px;
    height: 25px;
  }

  .nav--login-btn {
    width: 60px;
    height: 30px;
    font-size: 10px;
    padding: 5px;
    border: 2px solid aliceblue;
    border-radius: 10px;
  }

  .nav--user-info > img {
    width: 40px;
    height: 40px;
    padding-right: 10px;
  }

  .test-button {
    width: 50px;
    height: 50px;
  }

  .page--container {
    grid-template-rows: 80px 1fr;
    margin-bottom: 0px;
    height: calc(100dvh - 80px);
  }

  .div--ribbon {
    height: 80px;
    font-size: 32px;
    padding: 0px;
    display: grid;
    place-content: center;
    text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.3);
  }

  .div--not-authorized {
    font-size: 14px;
  }

  .div--not-authorized > img {
    width: 100px;
    height: 100px;
  }

  .cart-btn-light {
    position: absolute;
    z-index: 3;
    width: 6px;
    height: 6px;
    place-self: start end;
  }

  .nav--user-info > img {
    width: 30px;
    height: 30px;
  }

  .div--error {
    font-size: 16px;
  }

  .div--error-sign {
    font-size: 32px;
  }

  .my-profile--event-date--reschedule {
    border-radius: 15px;
    background-size: 14px;
    width: 30px;
    height: 30px;
  }

  .small-circle {
    border-radius: 10px;
    background-size: 8px;
    width: 15px;
    height: 15px;
  }

  .profile-pic-2 {
    width: 60px;
    height: 60px;
  }

  .highlight--text-1 {
    border-radius: 10px;
    padding: 0px 6px 0px 6px;
  }
  
}