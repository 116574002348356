

.page--container {
    display: grid;
    grid-template-rows: 1fr;
}

.tyc--container {
    display: grid;
    grid-template-rows: 150px 1fr;
    width: 100%;
    place-items: start center;
}

.tyc--container-title {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    place-self: center;
    font-size: 28px;
    color: rgb(70, 70, 70);
    font-family: monospace;
}

.tyc--container-title-deco {
    display: grid;
    grid-auto-flow: column;
    column-gap: 5px;
    padding-right: 10px;
}

.tyc--container-title-deco > div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.first--color {
    background-color: rgb(166, 138, 206);
}

.second--color {
    background-color: rgb(218, 200, 221);
}

.third--color {
    background-color: aliceblue;
}

.tyc--container-footer {
    height: 50px;
}

@media (max-width: 768px) {
    .page--container {
        width: 100dvw;
        height: 100dvh;
    }

    .tyc--container {
        grid-template-rows: 100px 1fr;
    }

    .tyc--container-title {
        font-size: 18px;
    }

    .tyc--container-footer {
        height: 30px;
    }
}