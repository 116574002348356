.login--popup-screen {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(150, 150, 150, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.login--popup-window {
    width: 400px;
    height: 350px;
    border-radius: 35px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(120, 120, 120);
    font-size: 24px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr;
    box-shadow: 0px 0px 7px rgba(100, 100, 100, 0.2);
    overflow: hidden;
    position: fixed;
}

.login--popup-window-title {
    font-size: 20px;
    background-color: none;
    color: rgb(140, 140, 140);
    text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    grid-template-rows: 1fr;
    transition: 0.2s;
    background-color: rgb(245, 245, 245);
}

.lock--logo {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    place-self: center;
    background-image: url("../../public/lock.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.login--popup-window-title-login-btn {
    place-self: center;
    height: 100%;
    display: grid;
    place-items: center;
    grid-auto-flow: column;
}

.login--popup-window-title-close-btn {
    place-self: center;
    display: grid;
    place-content: center center;
    text-align: center;
    border: 3px solid rgb(240, 138, 138);
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    background-color:  rgb(240, 138, 138);
    color: rgb(245, 245, 245);
    width: 35px;
    height: 35px;
    border-radius: 10px;
}

.login--popup-window-title-close-btn:hover {
    background-color: transparent;
    color: rgb(240, 138, 138);
    transition: 0.3s;
}

.login--popup-window-body {
    padding-top: 10px;
    display: grid;
    background-color: white;
    grid-template-rows: 1fr 1fr 60px 60px;
}

.login--popup-window-email {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login--popup-window-email > input {
    border: none;
    box-shadow: 2px 2px 4px rgba(120, 120, 120, 0.2);
    border-radius: 15px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    color: rgb(70, 70, 70);
    height: 35px;
    width: 300px;
}

.login--popup-window-email > input:valid {
    border: 2px solid rgb(138, 182, 243);
}

.login--popup-window-pw {
    display: flex;
    align-items: center;
    justify-content: center;
}

.login--popup-window-pw > input {
    border: none;
    box-shadow: 2px 2px 4px rgba(120, 120, 120, 0.2);
    border-radius: 15px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    color: rgb(70, 70, 70);
    height: 35px;
    width: 300px;
}

.login--popup-window-pw > input:valid {
    border: 2px solid rgb(138, 182, 243);
}

.login--popup-window-free {
    place-self: center;
    display: grid;
    width: 80%;
    place-items: center;
    font-size: 16px;
    color: rgb(80, 80, 80);
    font-family: monospace;
    text-align: center;
}

.login--popup-window-submit {
    display: grid;
    place-items: center;
}

.login--popup-window-submit > button {
    display: grid;
    position: relative;
    place-items: center;
    place-content: center;
    text-align: center;
    border: none;
    border-radius: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(120, 120, 120, 0.2);
    font-size: 16px;
    color: white;
    padding: 8px;
    width: 150px;
    height: 42px;
    background-color: rgb(166, 138, 206);
    box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
    border: 3px solid rgb(166, 138, 206);
}

.login--popup-window-submit > button:hover {
    background-color: transparent;
    color: rgb(166, 138, 206);
    transition: 0.2s;
}

.login--popup-window-register {
    display: grid;
    width: 100%;
    height: 100%;
    font-size: 16px;
    color: rgb(70, 70, 70);
    place-self: center;
    place-items: center;
    margin-bottom: 20px;
}

.notification {
    place-self: center;
    background-color: blanchedalmond;
    border-radius: 10px;
    padding: 8px;
}

.response--message {
    place-self: center;
    display: grid;
    place-items: center;
    place-content: center;
}

@media (max-width: 768px) {
    .login--popup-screen {
        width: 100dvw;
        height: 100dvh;
    }

    .login--popup-window {
        width: 300px;
        height: 250px;
        border-radius: 30px;
        grid-template-rows: 45px 1fr;
    }

    .login--popup-window-title {
        font-size: 16px;
    }

    .lock--logo {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }

    .login--popup-window-title-close-btn {
        width: 28px;
        height: 28px;
        font-size: 11px;
        border-radius: 8px;
        border: 2px solid rgb(240, 138, 138);
    }

    .login--popup-window-body {
        grid-template-rows: 1fr 1fr 40px 40px;
    }

    .login--popup-window-email > input {
        display: grid;
        place-self: center;
        height: 25px;
        width: 200px;
        font-size: 13px;
    }

    .login--popup-window-pw > input {
        display: grid;
        place-self: center;
        height: 25px;
        width: 200px;
        font-size: 13px;
    }

    .login--popup-window-free {
        font-size: 11px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .login--popup-window-submit > button {
        font-size: 12px;
        padding: 8px;
        width: 100px;
        height: 32px;
        border-radius: 15px;
        border: 3px solid rgb(166, 138, 206);
    }

    .login--popup-window-register {
        font-size: 12px;
        margin-bottom: 15px;
    }
    
    .notification {
        border-radius: 10px;
        padding: 5px;
    }
}
