

.page--container {
    display: grid;
    grid-template-rows: 1fr;
}

.faq--container {
    display: grid;
    grid-template-rows: 250px 1fr;
    width: 100%;
    place-items: start center;
    row-gap: 10px;
}

.faq--container-title {
    width: 50%;
    display: grid;
    text-align: center;
    grid-auto-flow: row;
    place-items: center;
    place-self: center;
    font-size: 22px;
    color: rgb(70, 70, 70);
    font-family: monospace;
    row-gap: 20px;
}

.class--formats {
    display: grid;
    place-items: center;
    grid-template-rows: 1fr;
    row-gap: 30px;
    padding-bottom: 20px;
}

.class--formats > div {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.new--tag {
    display: grid;
    place-items: center;
    border-radius: 10px;
    margin-right: 10px;
    width: 50px;
    height: 25px;
    color: white;
    background-color: rgb(110, 207, 198);
    font-size: 12px;
    font-weight: bold;
    font-family: monospace;
    text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
    box-shadow: 2px 2px 3px rgba(100, 100, 100, 0.2);
    overflow: hidden;
}

.class--formats-row {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 40px 160px;
    row-gap: 10px;
    grid-template-areas: "title price"
    "details details";
}

.class--format-title {
    grid-area: title;
    background: linear-gradient(90deg, rgb(138, 182, 243), rgb(166, 138, 206));
    font-weight: bold;
    color: white;
    padding: 5px 10px 5px 10px;
    border-radius: 15px;
    font-size: 13px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-shadow: 1px 1px 1px rgba(150, 150, 150, 0.2);
    text-align: center;
    text-decoration: none;
}

.class--format-price {
    grid-area: price;
    background-color: #ffc107;
    font-weight: bold;
    padding: 5px 10px 5px 10px;
    border-radius: 15px;
    font-size: 13px;
    text-shadow: 1px 1px 1px rgba(150, 150, 150, 0.2);
    text-align: center;
}

.slim--padding {
padding: 1.5px 8px 1.5px 8px;
}

.class--format-details {
    grid-area: details;
    margin: 10px;
    display: grid;
    place-items: center;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(220, 220, 220);
    border-radius: 10px;
    height: 100%;
    width: 90%;
    overflow: auto;
    padding-right: 25px;
}

.faq--container-footer {
    height: 30px;
}


@media (max-width: 768px) {
    .page--container {
        width: 100dvw;
        height: 100dvh;
    }

    .faq--container {
        grid-template-rows: 180px 1fr;
    }

    .faq--container-title {
        width: 70%;
        font-size: 16px;
        row-gap: 10px;
    }

    .faq--container-title > img {
        width: 90px;
        height: 90px;
    }

    .new--tag {
        width: 40px;
        height: 20px;
        margin-right: 5px;
        font-size: 10px;
        border-radius: 8px;
    }

    .class--formats {
        grid-template-rows: 1fr;
        row-gap: 20px;
    }

    .class--format-title {
        padding: 3px 8px 3px 8px;
        border-radius: 10px;
        font-size: 10px;
        border-radius: 10px;
    }

    .class--format-price {
        padding: 3px 8px 3px 8px;
        border-radius: 10px;
        font-size: 10px;
    }

    .slim--padding {
        padding: 1px 5px 1px 5px;
    }

    .class--format-details {
        padding-right: 20px;
        border-width: 1px;
    }

    .class--formats-row {
        row-gap: 3px;
    }

}