.div--content {
    padding-top: 100px;
}

.div--contact {
    display: grid;
    place-items: center;
    padding-top: 0px;
    padding-bottom: 30px;
}

.mail--wrapper {
    height: 300px;
    width: 600px;
    background-color: #7937c9;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 0;
    margin-top: 200px;
    box-shadow: 1px 1px 2px rgba(150, 150, 150, 0.4);

}
  
.mail--lid {
    position: absolute;
    height: 200px;
    width: 0;
    top: 0;
    left: 0;
    border-right: 300px solid transparent;
    border-bottom: 100px solid transparent;
    border-left: 300px solid transparent;
    transform-origin: top;
    transition: transform 0.25s linear;
}
  
.mail--lid.one {
    border-top: 200px solid #8965ed;
    /* transform: rotateX(0deg); */
    z-index: 2;
    transition-delay: 0.75s;
}
  
.mail--lid.two {
    border-top: 100px solid #7937c9;
    /* transform: rotateX(90deg); */
    z-index: 1;
    transition-delay: 0.5s;
}
  
.mail--envelope {
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    left: 0;
    border-top: 150px solid transparent;
    border-right: 300px solid #d7c4f0;
    border-bottom: 150px solid #d7c4f0;
    border-left: 300px solid #ada4f2;
    z-index: 3;
}

.mail--letter {
    position: absolute;
    top: 0;
    width: 90%;
    height: 80%;
    background-color: aliceblue;
    border-radius: 15px;
    z-index: 2;
    transition: 0.5s;
    transition-delay: 0.5s;
    display: grid;
    grid-template-rows: 50px 50px 180px;
    padding: 10px;
    place-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    box-shadow: 1px 1px 2px rgba(150, 150, 150, 0.2);
}

.mail--letter-from {
    display: grid;
    font-family: monospace;
    color: rgb(120,120,120);
    font-size: 16px;
    grid-template-columns: 80px 1fr 80px;
    place-items: center;
}

.mail--letter-from > input {
    border: none;
    box-shadow: 0px 0px 5px rgba(150, 150, 150, 0.2);
    border-radius: 10px;
    height: 25px;
    text-align: center;
}

.mail--letter-send-btn {
    width: 40px;
    height: 40px;
    border: none;
    background-color: #ffc107;
    color: white;
    font-size: 28px;
    font-weight: bold;
    border-radius: 40px;
    display: grid;
    place-content: center center;
    margin-right: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 3px rgba(100, 100, 100, 0.2);
    place-self: center;
    overflow: hidden;
}

.mail--letter-status-bar {
    width: 100%;
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    place-items: center;
}

.mail--letter-status-bar--counter {
    color: rgb(120,120,120);
    font-size: 14px;
    display: grid;
    grid-auto-flow: column;
}

.mail--letter-status-bar--message {
    font-size: 12px;
    overflow: auto;
    text-align: center;
    font-family: monospace;
    color: rgb(127, 103, 175);
}

.mail--letter-message {
    width: 100%;
    height: 100%;
    padding: 0px;
    display: grid;
    place-items: center;
}

.mail--letter-message > textarea {
    width: 90%;
    line-height: 20px;
    height: 100%;
    border: none;
    box-shadow: 0px 0px 3px rgba(150, 150, 150, 0.2);
    border-radius: 10px;
    padding: 5px;
    place-self: center;
    overflow: auto;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

@keyframes openingLetter {
    from {
        transform: translateY(0px);
        height: 100%;
    }
    to {
        transform: translateY(-250px);
        height: 150%;
    }
}

@keyframes openingLidOne {
    from {
        transform: rotateX(0deg);
    }
    to {
        transform: rotateX(90deg);
    }
}

@keyframes openingLidTwo {
    from {
        transform: rotateX(0deg);
    }
    to {
        transform: rotateX(180deg);
    }
}
  
.opening-letter {
    animation: 0.5s both openingLetter;
    transition-delay: 0.5s;
}

.opening-lid-one {
    animation: 0.5s both openingLidOne;
    transition-delay: 0.5s;
}

.opening-lid-two {
    animation: 0.5s both openingLidTwo;
    transition-delay: 0s;
}

@media (max-width: 768px) {
    .div--content {
        padding-top: 20px;
    }
    .mail--wrapper {
        height: 150px;
        width: 300px;
        margin-top: 200px;
    }

    .mail--envelope {
        border-top: 75px solid transparent;
        border-right: 150px solid #d7c4f0;
        border-bottom: 75px solid #d7c4f0;
        border-left: 150px solid #ada4f2;
    }

    .mail--lid {
        border-right: 150px solid transparent;
        border-bottom: 75px solid transparent;
        border-left: 150px solid transparent;
    }

    .mail--wrapper:hover .mail--letter {
        height: 100%;
    }

    .div--contact {
        padding-top: 60px;
    }

    .mail--letter-from {
        grid-template-columns: 30px 1fr 30px;
        font-size: 14px;
    }

    .mail--letter-from > input {
        font-size: 11px;
        width: 140px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .mail--letter-send-btn {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        font-size: 20px;
        margin-right: 0px;
    }

    .mail--letter-status-bar {
        grid-template-columns: 50px 1fr 50px;
    }

    .mail--letter-status-bar--counter {
        font-size: 12px;
    }

    .mail--letter-status-bar--message {
        font-size: 10px;
    }

    .mail--letter-message > textarea {
        font-size: 12px;
    }

    @keyframes openingLetter {
        from {
            transform: translateY(0px);
            height: 100%;
        }
        to {
            transform: translateY(-250px);
            height: 200%;
        }
    }
}