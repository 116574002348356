.popup-screen {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(150, 150, 150, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-window {
    margin-top: 0px;
    width: 400px;
    max-height: 300px;
    border-radius: 35px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(120, 120, 120);
    font-size: 24px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr 30px;
    box-shadow: 0px 0px 7px rgba(100, 100, 100, 0.2);
    overflow: hidden;
    position: fixed;
    transition: 0.2s;
}

.fullscreen {
    width: 100%;
    max-height: 100%;
    height: 100%;
    margin-top: 0px;
}

.popup-window-title {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    place-items: center;
    background-color: rgb(249, 249, 249);
}

.popup-window-title-close-btn {
    place-self: center;
    display: grid;
    place-items: center;
    place-content: center;
    border: 3px solid rgb(240, 138, 138);
    font-size: 16px;
    background-color:  rgb(240, 138, 138);
    color: rgb(245, 245, 245);
    width: 35px;
    height: 35px;
    border-radius: 10px;
    margin-right: 25px;
}

.popup-window-title-close-btn:hover {
    background-color: transparent;
    color: rgb(240, 138, 138);
    transition: 0.3s;
}

.popup-window-body {
    display: grid;
    place-items: center;
    background-color: white;
    overflow-y: auto;
    overflow-x: hidden;
}

.popup-window-body--row {
    display: grid;
    place-items: center;
    width: 95%;
    height: 60px;
    grid-template-columns: 50px 1fr 1fr 2fr;
    grid-template-rows: 1fr;
    overflow: auto;
    font-size: 13px;
}

.profile--logo {
    background-image: url("../../public/kitty_face.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 40px;
    width: 40px;
    height: 40px;
}

.popup-window-body--row > div {
    display: grid;
    place-items: center;
    text-align: center;
}

.popup-window-footer {
    display: grid;
    place-items: center;
    background-color: white;
}

@media (max-width: 768px) {
    .popup-screen {
        width: 100dvw;
        height: 100dvh;
    }

    .popup-window {
        margin-top: 0px;
        width: 300px;
        max-height: 250px;
        border-radius: 30px;
        grid-template-rows: 45px 1fr 15px;
    }

    .fullscreen {
        width: 100dvw;
        max-height: 100dvh;
        height: 100dvh;
        margin-top: 0px;
    }

    .popup-window-title {
        font-size: 16px;
    }

    .popup-window-title-close-btn {
        width: 28px;
        height: 28px;
        font-size: 12px;
        border-radius: 8px;
        border: 2px solid rgb(240, 138, 138);
    }

    .popup-window-body--row {
        height: 50px;
        grid-template-columns: 40px 1fr 1fr 2fr;
        grid-template-rows: 1fr;
        overflow: auto;
        font-size: 11px;
    }

    .profile--logo {
        background-size: 30px;
        width: 30px;
        height: 30px;
    }
}
