.rm--container {
    place-self: center;
    display: grid;
    place-items: center;
    width: 100%;
}

.rm--body {
    width: 500px;
    height: 350px;
    display: grid;
    place-items: center;
    place-self: center;
    grid-template-rows: 60px 1fr 1fr 70px;
    grid-template-columns: 1fr;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0px 0px 6px rgba(120, 120, 120, 0.2);
    margin-top: 30px;
}

.rm--body-title {
    background-color: #ffc107;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    font-size: 18px;
    color: black;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(100, 100, 100, 0.2);
}

.rm--body-title--container {
    display: grid;
    place-items: center;
    grid-auto-flow: column;
    column-gap: 10px;
}

.reschedule-icon {
    background-image: url("../../public/refresh.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22px;
    width: 25px;
    height: 25px;
}

.rm--body-event {
    width: 100%;
    height: 100%;
    font-family: monospace;
    font-size: 16px;
    text-align: center;
    display: grid;
    grid-template-rows: 1fr 2fr;
}

.rm--body-event--placeholder {
    width: 95%;
    place-self: center;
    display: grid;
    place-items: center;
    font-family: monospace;
    font-size: 16px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.rm--body-event--text {
    place-self: center;
    place-items: center;
    width: 95%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.rm--body-event--details {
    place-self: center;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 3fr;
    place-items: center;
    width: 95%;
    height: 60px;
    font-family: monospace;
    font-size: 12px;
    border: 3px dashed rgba(100, 100, 100, 0.2);
    border-radius: 15px;
    transition: 0.2s;
}

.rm--body-event--details:hover {
    background-color: rgb(228, 240, 255);
  }

.rm--body-event--delete-btn {
    place-self: center;
    display: grid;
    place-items: center;
    background-color: inherit;
    border-radius: 10px;
    color: rgb(240, 138, 138);
    font-family: monospace;
    font-weight: bold;
    width: 35px;
    height: 35px;
    border: 3px solid rgb(240, 138, 138);
    font-size: 16px;
    transition: 0.2s;
}

.rm--body-event--delete-btn:hover {
    background-color: rgb(240, 138, 138);
    color: white;
}

.rm--body-event--date {
    font-size: 14px;
    font-weight: bold;
    background-color: rgb(166, 138, 206);
    color: white;
    border-radius: 15px;
    place-self: center;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.rm--body-event--time {
    font-size: 14px;
    font-weight: bold;
    background-color: rgb(110, 207, 198);
    color: white;
    border-radius: 15px;
    place-self: center;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.rm--body-event--type {
    font-size: 14px;
    font-weight: bold;
    color: rgb(100, 100, 100);
    place-self: center;
    text-align: center;
}

.rm--body--confirm-btn {
    border: none;
    display: grid;
    place-items: center;
    border-radius: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(100, 100, 100, 0.5);
    color: white;
    background-color: rgb(191, 116, 177);
    box-shadow: 0px 0px 6px rgba(150, 150, 150, 0.5);
    text-shadow: 1px 1px 2px rgba(150, 150, 150, 0.3);
    font-size: 14px;
    width: 120px;
    height: 40px;
}

.rm--body--cancel-btn {
    border: none;
    display: grid;
    place-items: center;
    border-radius: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    color: rgb(240, 138, 138);
    background-color: transparent;
    border: 3px solid rgb(240, 138, 138);
    font-size: 14px;
    width: 120px;
    height: 40px;
    transition: 0.2s;
}

.rm--body--cancel-btn:hover {
    color: white;
    background-color: rgb(240, 138, 138);
    box-shadow: 0px 0px 6px rgba(150, 150, 150, 0.5);
    text-shadow: 1px 1px 2px rgba(150, 150, 150, 0.3);
}

@media (max-width: 768px) {
    .rm--body {
        width: 350px;
        height: 280px;
        grid-template-rows: 40px 1fr 1fr 50px;
        border-radius: 35px;
        margin-top: 25px;
    }

    .rm--body-title {
        font-size: 14px;
    }

    .rm--body-title--container {
        column-gap: 5px;
    }

    .reschedule-icon {
        background-image: url("../../public/refresh.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        width: 20px;
        height: 20px;
    }

    .rm--body-event {
        font-size: 12px;
    }

    .rm--body-event--placeholder {
        font-size: 12px;
    }

    .rm--body-event--details {
        height: 45px;
    }

    .rm--body-event--delete-btn {
        border-radius: 8px;
        width: 25px;
        height: 25px;
        border: 2px solid rgb(240, 138, 138);
        font-size: 14px;
    }

    .rm--body-event--date {
        font-size: 11px;
        border-radius: 15px;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .rm--body-event--time {
        font-size: 11px;
        border-radius: 15px;
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .rm--body-event--type {
        font-size: 11px;
    }

    .rm--body--confirm-btn {
        border-radius: 10px;
        font-size: 12px;
        width: 110px;
        height: 30px;
    }

    .rm--body--cancel-btn {
        border-radius: 10px;
        border: 2px solid rgb(240, 138, 138);
        font-size: 12px;
        width: 110px;
        height: 30px;
    }
    
}