
.recover--body {
    place-self: center;
    place-items: center;
    place-content: center;
    display: grid;
    width: 400px;
    padding-bottom: 30px;
    row-gap: 15px;
    padding-top: 30px;
}

.recover--body > img {
    width: 180px;
    height: 180px;
}

.recover--form {
    display: grid;
    grid-auto-flow: row;
    place-items: start center;
    grid-template-columns: 30px 1fr;
    column-gap: 30px;
    height: 32px;
    padding-top: 20px;
}

.form--validation-circle {
    place-self: center;
    height: 30px;
    width: 30px;
    background-color: rgb(218, 200, 221);
    border-radius: 30px;
    box-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2);
}

.recover--form > input {
    border: none;
    box-shadow: 0px 0px 5px rgba(150, 150, 150, 0.4);
    border-radius: 10px;
    text-align: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    color: rgb(70, 70, 70);
    height: 35px;
    width: 300px;
}

.recover--status {
    height: 90px;
    display: grid;
    place-items: center;
    text-align: center;
    font-size: 14px;
    color: rgb(80, 80, 80);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.recover--submit {
    display: grid;
    place-items: center;
}

.recover--submit-btn {
    place-self: center;
    border: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(100, 100, 100, 0.5);
    background-color: rgb(166, 138, 206);
    color: white;
    width: 100px;
    height: 40px;
    border-radius: 15px;
    box-shadow: 2px 2px 2px rgba(100, 100, 100, 0.2);
}

@media (max-width: 768px) {

    .recover--body {
        width: 300px;
        padding-top: 10px;
        padding-bottom: 20px;
        row-gap: 10px;
    }

    .recover--body > img {
        width: 120px;
        height: 120px;
    }

    .recover--title {
        height: 80px;
        place-items: center start;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 22px;
        color: rgb(70, 70, 70);
        text-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2);
    }

    .recover--form {
        place-items: start center;
        grid-template-columns: 20px 1fr;
        column-gap: 20px;
        padding-top: 20px;
    }

    .form--validation-circle {
        height: 20px;
        width: 20px;
        border-radius: 20px;
    }

    .recover--form > input {
        border-radius: 10px;
        font-size: 12px;
        color: rgb(70, 70, 70);
        height: 25px;
        width: 200px;
    }

    .recover--status {
        height: 80px;
        font-size: 13px;
    }

    .recover--submit-btn {
        width: 100px;
        height: 30px;
        border-radius: 10px;
        font-size: 12px;
    }

}