.scheduler--container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding-top: 30px;
    padding-bottom: 30px;
}

.calendar--container {
    display: grid;
}

.calendar--sheet {
    place-self: center;
    width: 500px;
    height: 500px;
    display: grid;
    grid-template-rows: 50px 50px 1fr 50px;
    grid-template-columns: 1fr;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0px 0px 6px rgba(100, 100, 100, 0.25);
}

.calendar--top {
    background-color: rgb(117, 214, 201);
    display: grid;
}

.calendar--title {
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 3fr 1fr;
    background-color: rgb(249, 249, 249);
    font-family: 'Arial light', Helvetica, sans-serif;
    font-size: 26px;
    color: rgb(120, 120, 120);
    text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
}

.left-arrow-btn {
    place-self: center end;
    display: grid;
    place-items: center;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
}

.left-arrow-logo {
    position: relative;
    width: 0px;
    height: 0px;
    border: 12px solid rgb(120, 120, 120);
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-top-width: 10px;
    border-left-width: 0px;
    border-bottom-width: 10px;
}

@keyframes BounceLeft {
    0%, 100% {
        left: 0px;
    }
    20% {
        left: -5px;
    }
}

.left-arrow-btn:active > div {
    animation-name: BounceLeft;
    animation-duration: 0.2s;
}

.right-arrow-btn {
    place-self: center start;
    display: grid;
    place-items: center;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
}

.right-arrow-logo {
    position: relative;
    width: 0px;
    height: 0px;
    border: 12px solid rgb(120, 120, 120);
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-top-width: 10px;
    border-right-width: 0px;
    border-bottom-width: 10px;
}

@keyframes BounceRight {
    0%, 100% {
        left: 0px;
    }
    20% {
        left: 5px;
    }
}

.right-arrow-btn:active > div {
    animation-name: BounceRight;
    animation-duration: 0.2s;
}

.calendar--grid {
    background-color: rgb(249, 249, 249);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(7, 1fr);
    width: 100%;
    height: 100%;
}

.calendar--bottom {
    background-color: rgb(249, 249, 249);
}

.calendar--weekdays {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 1fr;
    background-color: white;
}

.calendar--day-title {
    place-self: center;
    border: none;
    border-radius: 30px;
    width: 45px;
    height: 45px;
    display: grid;
    place-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    background-color: rgb(166, 138, 206);
    color: white;
    box-shadow: 2px 2px 3px rgba(100, 100, 100, 0.2);
}

.weekend {
    background-color: rgb(218, 200, 221);
}

.calendar--day {
    place-self: center;
    border: 1px solid rgb(249, 249, 249);
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    font-family: monospace;
    font-size: 16px;
    color: rgb(115, 115, 115);
    text-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2);
    transition: 0.2s;
}

.calendar--day--number {
    display: grid;
    place-items: center;
    place-self: center;
}

.is-available {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 3px dashed rgb(117, 214, 201);
}

.off-month--circle {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 3px dashed rgb(242, 242, 242);
}

.is-today--circle {
    border: 3px dashed aliceblue;
}

.off-month {
    background-color: rgb(194, 221, 246);
}

.is-today {
    background-color: rgb(191, 116, 177);
    color: aliceblue;
}

.event-scheduler--container {
    display: grid;
}

.event-scheduler--body {
    place-self: center;
    display: grid;
    width: 500px;
    height: 500px;
    grid-template-columns: 1fr;
    grid-template-rows: 70px 1fr;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0px 0px 6px rgba(100, 100, 100, 0.25);
    background-color: aliceblue;
}

.body-available {
    grid-template-rows: 70px 30px 1fr;
}

.event-scheduler--title {
    background-color: rgb(166, 138, 206);
    color: white;
    display: flex;
    justify-content: left;
    padding-left: 50px;
    align-items: center;
    transition: 0.2s;
}

.event-scheduler--title-add-btn {
    width: 50px;
    height: 50px;
    border: none;
    background-color: #ffc107;
    color: white;
    font-size: 36px;
    font-weight: bold;
    border-radius: 50%;
    display: grid;
    place-content: center;
    place-items: center;
    margin-right: 10px;
    overflow: hidden;
    box-shadow: 2px 2px 3px rgba(100, 100, 100, 0.2);
}

.event-scheduler--title > p {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 26px;
    text-shadow: 2px 2px 3px rgba(139, 139, 139, 0.4);
}

.event-scheduler--title-tag {
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    border-radius: 20px;
    margin-left: 15px;
    padding: 9px 12px;
    color: rgb(80, 80, 80);
    font-size: 12px;
    font-weight: bold;
    font-family: monospace;
    text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
    box-shadow: 2px 2px 3px rgba(100, 100, 100, 0.2);
    overflow: hidden;
    column-gap: 8px;
}

.yellow {
    background-color: #ffc107;
    color: rgb(60, 60, 60);
}

.event-scheduler--title-day {
    width: 50px;
    height: 50px;
    background-color: rgb(249, 249, 249);
    border-radius: 30px;
    display: grid;
    margin-right: 15px;
    grid-template-rows: 15px 1fr;
    overflow: hidden;
    box-shadow: 2px 2px 3px rgba(100, 100, 100, 0.2);
}

.event-scheduler--title-number {
    display: grid;
    align-items: start;
    justify-content: center;
    color: rgb(85, 85, 85);
    font-family: monospace;
    font-size: 24px;
}

.event-scheduler--title-month {
    width: 100%;
    height: 100%;
    place-self: center;
    display: grid;
    place-items: center;
    background-color: rgb(240, 138, 138);
    color: white;
    font-family: 'Arial';
    font-size: 10px;
}

.event-scheduler--message {
    width: 100%;
    height: 30px;
    display: grid;
    place-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    background-color: rgb(194, 221, 246);
    color: rgb(60, 60, 60);
    text-shadow: 1px 1px 1px rgba(120, 120, 120, 0.2);
    transition: 0.2s;
}

.message--admin {
    background-color: rgb(191, 116, 177);
    color: white;
}

.event-scheduler--list {
    padding: 10px;
    overflow: auto;
    display: grid;
    grid-auto-rows: 100px;
}

.event-scheduler--list > img {
    align-self: end;
    justify-self: center;
}

.event-scheduler--list-no-items {
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    font-family: monospace;
    font-size: 18px;
    color: rgb(80, 80, 80);
    text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
    max-width: 80%;
    place-self: center;
}

.event-scheduler--event {
    position: relative;
    place-self: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgb(255, 255, 255);
    height: 90px;
    width: 430px;
    margin-bottom: 10px;
    font-family: monospace;
    color: rgb(65, 65, 65);
    border-radius: 5px;
    box-shadow: 2px 2px 1px rgba(120, 120, 120, 0.2);
    display: grid;
    grid-template-columns: 10fr 9fr 11fr 10fr;
    grid-template-rows: 1fr;
    overflow: hidden;
}

.event-scheduler--event:hover {
    transition: 0.2s;
    height: 100px;
    width: 440px;
    box-shadow: 2px 2px 3px rgba(100, 100, 100, 0.3);
}

.event-scheduler--event-time {
    place-self: center;
    background-color: rgb(117, 214, 201);
    font-size: 20px;
    color: white;
    padding: 8px;
    text-shadow: 2px 2px 1px rgba(100, 100, 100, 0.3);
    width: 90px;
    height: 45px;
    display: grid;
    place-items: center;
    grid-auto-flow: column;
    border-radius: 25px 25px 25px 0px;
    text-align: center;
}

.event-scheduler--event-time > p {
    font-size: 14px;
}

.event-scheduler--event-duration {
    place-self: center;
    font-size: 12px;
    display: grid;
    place-items: center;
    row-gap: 3px;
    border: none;
    background-color: transparent;
}

.event-scheduler--event-duration-title {
    font-family: monospace;
    font-size: 12px;
}

.event-scheduler--event-type {
    place-self: center;
    font-size: 16px;
    display: grid;
    place-items: center;
    place-content: center;
    max-height: 100%;
    max-width: 95%;
    overflow-y: auto;
    border: none;
    background-color: transparent;
}

.profile-pic {
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.event-scheduler--event-type-title {
    font-family: monospace;
    font-size: 12px;
    text-align: center;
}

.event-duration-time--box {
    display: grid;
    place-items: center;
    place-content: center;
    grid-auto-flow: column;
    column-gap: 6px;
}

.event-duration-time--box > img {
    width: 15px;
    height: 15px;
    display: inline;
}

.event-scheduler--event-topic {
    font-size: 12px;
    text-align: center;
}

.event-scheduler--event-participants {
    font-size: 12px;
}

.event-participants--counter {
    font-weight: bold;
}

.event-scheduler--event-info {
    place-self: center;
}


.event-scheduler--buy-btn {
    place-self: center;
    border: 3px solid rgb(191, 116, 177);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 13px;
    background-color: rgb(191, 116, 177);
    color: white;
    width: 85px;
    height: 42px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: 0.2s;
}

.event-scheduler--buy-btn:hover {
    background-color: white;
    color: rgb(191, 116, 177);
}

.event-scheduler--buy-btn:focus {
    background-color: white;
    color: rgb(191, 116, 177);
}

.event-scheduler--buy-btn-placeholder {
    place-self: center;
    display: grid;
    place-items: center;
    font-size: 13px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(100, 100,100);
}

.event-scheduler--mycart-btn {
    place-self: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 13px;
    background-color: rgb(138, 182, 243);
    background-image: url("../../public/cart.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
    border: none;
    color: white;
    width: 85px;
    height: 42px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: 0.2s;
    box-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2);
}

.event-scheduler--reschedule-btn {
    place-self: center;
    border: 3px solid #ffc107;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 13px;
    background-color: transparent;
    color: rgb(80, 80, 80);
    width: 85px;
    height: 42px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: 0.2s;
}

.event-scheduler--reschedule-btn:hover {
    background-color: #ffc107;
}

.popup--profile {
    place-self: center;
    display: grid;
    place-content: center;
    place-items: center;
    grid-template-rows: 80px 1fr;
    padding: 10px 30px 10px 30px;
}

.pic--name {
    display: grid;
    place-items: center;
    width: 80%;
    grid-auto-flow: column;
    grid-template-columns: 1fr 2fr;
    column-gap: 0px;
}

.profile--name {
    font-family: monospace;
    font-size: 28px;
    place-self: center;
}

.profile--topic {
    font-size: 20px;
    place-self: center;
    text-align: center;
    font-family: monospace;
}

.profile--description {
    font-size: 16px;
    text-align: justify;
    line-height: 1.5;
}

.profile--bullet {
    font-size: 16px;
}

.ok--bullet li {
    list-style-image: url("../../public/ok.svg");
}

.glitter--bullet li {
    list-style-type: "✨";
}

.profile--bullet li + li {
    margin-top: 15px;
}


@media (max-width: 768px) {
    .scheduler--container {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .calendar--sheet {
        width: 350px;
        height: 350px;
        grid-template-rows: 35px 35px 1fr 35px;
        border-radius: 35px;
    }

    .calendar--title {
        font-size: 18px;
    }

    .left-arrow-btn, .right-arrow-btn {
        width: 30px;
        height: 30px;
    }
    
    .left-arrow-logo {
        border: 9px solid rgb(120, 120, 120);
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom-color: transparent;
        border-top-width: 7px;
        border-left-width: 0px;
        border-bottom-width: 7px;
    }
    
    .right-arrow-logo {
        border: 9px solid rgb(120, 120, 120);
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-top-width: 7px;
        border-right-width: 0px;
        border-bottom-width: 7px;
    }

    .calendar--day-title {
        width: 30px;
        height: 30px;
        font-size: 10px;
    }

    .calendar--day {
        font-size: 13px;
    }

    .is-available {
        width: 25px;
        height: 25px;
        border: 2px dashed rgb(117, 214, 201);
    }
    
    .off-month--circle {
        width: 25px;
        height: 25px;
        border: 2px dashed rgb(242, 242, 242);
    }
    
    .is-today--circle {
        border: 2px dashed aliceblue;
    }

    .event-scheduler--container {
        padding-top: 20px;
    }

    .event-scheduler--body {
        width: 350px;
        height: 350px;
        border-radius: 35px;
        grid-template-rows: 50px 1fr;
    }

    .body-available {
        grid-template-rows: 50px 20px 1fr;
    }

    .event-scheduler--title {
        padding-left: 30px;
    }

    .event-scheduler--title > p {
        font-size: 20px;
    }

    .event-scheduler--title-add-btn {
        width: 40px;
        height: 40px;
        font-size: 28px;
    }

    .event-scheduler--title-day {
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }

    .event-scheduler--title-number {
        font-size: 18px;
    }

    .event-scheduler--title-month {
        font-size: 8px;
    }

    .event-scheduler--title-tag {
        padding: 7px 11px;
        border-radius: 15px;
        font-size: 10px;
        column-gap: 5px;
    }

    .event-scheduler--message {
        height: 20px;
        font-size: 10px;
    }

    .event-scheduler--list {
        padding: 5px;
        grid-auto-rows: 90px;
    }

    .event-scheduler--list-no-items {
        font-size: 14px;
    }

    .empty-kitty {
        width: 80px;
        height: 80px;
    }

    .event-scheduler--event {
        width: 310px;
        height: 80px;
    }

    .event-scheduler--event:hover {
        width: 315px;
        height: 85px;
    }

    .event-scheduler--event-time {
        width: 70px;
        height: 40px;
        font-size: 14px;
    }

    .event-scheduler--event-time > p {
        font-size: 12px;
    }

    .event-scheduler--event-duration {
        font-size: 13px;
        color: rgb(100, 100, 100);
        row-gap: 1px;
    }

    .event-scheduler--event-duration-title {
        font-size: 9px;
    }

    .event-scheduler--event-type-title {
        font-size: 9px;
        color: rgb(50, 50, 50);
    }

    .profile-pic {
        width: 40px;
        height: 40px;
    }

    .event-duration-time--box {
        column-gap: 4px;
        font-size: 9px;
        color: rgb(50, 50, 50);
    }

    .event-duration-time--box > img {
        width: 11px;
        height: 11px;
        display: inline;
    }

    .event-scheduler--event-topic {
        font-size: 9px;
        color: rgb(50, 50, 50);
    }

    .event-scheduler--event-participants {
        font-size: 9px;
    }

    .event-participants--counter {
        font-size: 9px;
    }

    .event-scheduler--buy-btn {
        width: 65px;
        height: 28px;
        font-size: 10px;
        border-radius: 10px;
        border: 2px solid rgb(191, 116, 177);
    }

    .event-scheduler--buy-btn-placeholder {
        font-size: 10px;
    }

    .event-scheduler--mycart-btn {
        width: 65px;
        height: 28px;
        border-radius: 10px;
        background-size: 20px;
        box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
    }

    .event-scheduler--reschedule-btn {
        width: 65px;
        height: 28px;
        font-size: 10px;
        border-radius: 10px;
        border: 2px solid #ffc107;
    }

    .popup--profile {
        grid-template-rows: 70px 1fr;
        padding: 5px 20px 5px 20px;
    }

    .pic--name {
        column-gap: 0px;
    }

    .profile--name {
        font-size: 20px;
    }

    .profile--topic {
        font-size: 16px;
    }
    
    .profile--description {
        font-size: 14px;
        line-height: 1.4;
    }
    
    .profile--bullet {
        font-size: 14px;
    }

    .ok--bullet {
        list-style-image: url("../../public/ok.svg");
    }
    
    .glitter--bullet {
        list-style-image: none;
        list-style-type: square;
    }
    
    .profile--bullet li + li {
        margin-top: 10px;
    }
    
}