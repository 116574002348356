
.my-profile-page--container {
  display: grid;
  width: 100%;
  height: 100%;
}

.my-profile--header {
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  place-items: center;
  place-self: center;
  width: 100%;
  background-color: rgb(191, 116, 177);
  box-shadow: 0px 0px 6px rgba(120, 120, 120, 0.2);
}

.my-profile--header-title {
  position: relative;
  font-family: 'Arial';
  font-size: 18px;
  display: grid;
  grid-template-rows: 40px 20px;
  grid-template-columns: 120px 1fr;
  place-items: center;
  grid-template-areas: "img name"
  "img email";
}

.my-profile--header-title > img {
  place-self: center;
  grid-area: img;
  width: 80px;
  height: 80px;
}

.my-profile--header-title > h1 {
  place-self: center start;
  grid-area: name;
  font-weight: normal;
  line-height: 0.5em;
  color: rgb(80, 80, 80);
  text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
}

.my-profile--header-title > h5 {
  place-self: center start;
  grid-area: email;
  font-weight: normal;
  line-height: 0.1em;
  font-size: 14px;
  color: white;
  text-shadow: 1px 1px 0px rgba(120, 120, 120, 0.2);
}

.my-profile--header-logout-btn {
  margin-top: 8px;
  background-color: transparent;
  font-size: 12px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold; 
  color: white;
  border: 3px solid white;
  border-radius: 15px;
  box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
  width: 110px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-profile--header-logout-btn:hover {
  transition: 0.2s;
  background-color: white;
  color: rgb(166, 138, 206);
  text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
}

.my-profile--container {
  display: grid;
  padding-bottom: 30px;
  padding-top: 0px;
  border-radius: 10px;
}

.my-profile--body {
  margin-top: 20px;
  place-self: center;
  width: 70%;
  height: 1200px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: 
  "upcoming"
  "history"
  "purchases";
  row-gap: 30px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.my-profile--body-header {
  font-weight: bold;
  display: grid;
  place-items: center;
  font-size: 16px;
  background-color: rgb(166, 138, 206);
  border-radius: 25px 25px 0px 0px;
  color: white;
  text-shadow: 1px 1px 1px rgba(100, 100, 100, 0.2);
}

.my-profile--body-header--container {
  display: grid;
  place-items: center;
  grid-auto-flow: column;
  column-gap: 10px;
}

.purchases-icon {
  background-image: url("../../public/cart.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;
  width: 25px;
  height: 25px;
}

.green--header {
  background-color: rgb(110, 207, 198);
}

.blue--header {
  background-color: rgb(138, 182, 243);
}

.red--header {
  background-color: rgb(240, 138, 138);
}

.my-profile--upcoming {
  grid-area: upcoming;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  box-shadow: 0px 0px 6px rgba(120, 120, 120, 0.2);
  border-radius: 25px;
  overflow: hidden;
}

.my-profile--history {
  grid-area: history;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  box-shadow: 0px 0px 6px rgba(120, 120, 120, 0.2);
  border-radius: 25px;
  overflow: hidden;
}

.my-profile--purchases {
  grid-area: purchases;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr;
  box-shadow: 0px 0px 6px rgba(120, 120, 120, 0.2);
  border-radius: 25px;
  overflow: hidden;
}

.my-profile--box {
  border-radius: 0px 0px 25px 25px;
  background-color: white;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 30px;
  grid-auto-rows: 60px;
  overflow: auto;
}

.my-profile--box-desc {
  display: grid;
  grid-auto-flow: column;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  color: rgb(150, 150, 150);
  background-color: rgb(248, 248, 248);
}

.my-profile--box-desc > div {
  place-self: center;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
}

.no--events {
  display: grid;
  place-items: center;
}

.my-profile--item-row {
  width: 100%;
  height: 60px;
  font-family: monospace;
  font-size: 12px;
  border-bottom: 3px dashed rgba(100, 100, 100, 0.2);
  transition: 0.2s;
}

.my-profile--item-row:hover {
  background-color: rgb(228, 240, 255);
}

.my-profile--event {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  font-family: monospace;
}

.my-profile--event-date {
  place-self: center;
  display: grid;
  grid-auto-flow: column;
  column-gap: 10px;
  padding-left: 10px;
}

.my-profile--event-date--reimbursement {
  place-self: center;
  display: grid;
  place-items: center;
  background-color: rgb(240, 138, 138);
  color: white;
  font-weight: bold;
  font-size: 18px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 1px 1px 1px rgba(150, 150, 150, 0.2);
}

.my-profile--event--link {
  box-shadow: 1px 1px 2px rgba(150, 150, 150, 0.2);
  border-radius: 20px;
  background-color: rgb(138, 182, 243);
  color: white;
  padding: 10px 15px 10px 15px;
  text-decoration: none;
}

.in-progress {
  font-size: 9px;
}

.my-profile--event--link:link {
  text-decoration: none;
}

.my-profile--event--link:visited {
  text-decoration: none;
}

.my-profile--event--link:hover {
  text-decoration: none;
}

.my-profile--event--link:active {
  text-decoration: none;
}

.my-profile--event-date--text {
  font-weight: bold;
  
  color: white;
  border-radius: 20px;
  place-self: center;
  padding: 10px 15px 10px 15px;
}

.bg-1 {
  background-color: rgb(218, 200, 221);
}

.bg-2 {
  background-color: rgb(205, 184.5, 217.25);
}

.bg-3 {
  background-color: rgb(192, 169, 213.5);
}

.bg-4 {
  background-color: rgb(179, 153.5, 209.75);
}

.bg-5 {
  background-color: rgb(166, 138, 206);
}

.bg-6 {
  background-color: rgb(159, 149, 215.25);
}

.bg-7 {
  background-color: rgb(152, 160, 224.5);
}

.bg-8 {
  background-color: rgb(145, 171, 233.75);
}

.bg-9 {
  background-color: rgb(138, 182, 243);
}

.bg-10 {
  background-color: rgb(158, 186.5, 237.5);
}

.bg-11 {
  background-color: rgb(178, 191, 232);
}

.bg-12 {
  background-color: rgb(198, 195.5, 226.5);
}

.my-profile--event-time {
  place-self: center;
  display: grid;
  grid-auto-flow: column;
  column-gap: 3px;
}

.my-profile--event-time--text {
  font-weight: bold;
  background-color: rgb(110, 207, 198);
  color: white;
  border-radius: 20px;
  place-self: center;
  padding: 10px 15px 10px 15px;
  text-align: center;
}

.my-profile--event-type {
  font-weight: bold;
  color: rgb(100, 100, 100);
  place-self: center;
  text-align: center;
  display: grid;
  grid-auto-flow: row;
  place-items: center;
}

.participants-btn {
  border: none;
  border-radius: 50%;
  background-color: rgb(191, 116, 177);
  background-image: url("../../public/profile.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;
  width: 25px;
  height: 25px;
}

@media (max-width: 768px) {
  .my-profile--container {
    padding-top: 0px;

  }

  .my-profile--header {
    padding: 0px;
  }

  .my-profile--header-title {
    position: relative;
    z-index: 1;
    font-family: 'Arial';
    font-size: 11px;
    grid-template-columns: 60px 1fr;
    grid-template-rows: 25px 10px;
    place-items: center start;
  }

  .my-profile--header-title > img {
    width: 40px;
    height: 40px;
  }

  .my-profile--header-title > h1 {
    font-weight: normal;
    line-height: 0.5em;
  }
  
  .my-profile--header-title > h5 {
    font-weight: normal;
    line-height: 1em;
    font-size: 10px;
  }

  .my-profile--header-logout-btn {
    margin-top: 5px;
    font-size: 9px;
    border: 2px solid white;
    width: 80px;
    height: 25px;
    border-radius: 10px;
  }

  .my-profile--body {
    max-width: 400px;
    height: 800px;
    row-gap: 15px;
  }

  .my-profile--body-header {
    font-size: 12px;
  }

  .my-profile--body-header--container {
    column-gap: 5px;
  }

  .purchases-icon {
    background-size: 20px;
    width: 20px;
    height: 20px;
  }

  .my-profile--box {
    grid-template-rows: 20px;
    grid-auto-rows: 40px;
  }

  .my-profile--box-desc {
    height: 20px;
    font-size: 10px;
  }

  .my-profile--upcoming {
    grid-template-rows: 35px 1fr;
  }

  .my-profile--history {
    grid-template-rows: 35px 1fr;
  }

  .my-profile--purchases {
    grid-template-rows: 35px 1fr;
  }

  .my-profile--item-row {
    height: 40px;
    font-size: 9px;
    border-bottom: 2px dashed rgba(100, 100, 100, 0.2);
  }

  .no--events {
    font-size: 12px;
  }

  .my-profile--event-date {
    column-gap: 5px;
    padding-left: 5px;
  }

  .my-profile--event-date--reimbursement {
    font-size: 16px;
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  .my-profile--event-date--text {
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
  }

  .my-profile--event-time--text {
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
  }

  .my-profile--event--link {
    border-radius: 10px;
    padding: 5px 10px 5px 10px;
  }

  .in-progress {
    font-size: 6px;
  }

  .participants-btn {
    width: 20px;
    height: 20px;
    background-size: 20px;
  }

}