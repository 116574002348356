.my-cart--container {
    padding-top: 30px;
    padding-bottom: 30px;
    display: grid;
}

.my-cart--body {
    place-self: center;
    width: 70%;
    background-color: rgb(249, 249, 249);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 80px 1fr 300px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 6px rgba(100, 100, 100, 0.25);
    padding-bottom: 5px;
}

.my-cart--title {
    width: 100%;
    font-family: 'Arial';
    font-size: 30px;
    color: rgb(80, 80, 80);
    text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 50px;
    background-color: white;
}

.my-cart--title-text {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 50px;
}

.my-cart--title-seal {
    display: grid;
    margin-left: 15px;
    background-image: url("../../public/seal.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 40px;
    height: 40px;
    place-items: center;
    font-family: monospace;
    font-weight: bold;
    color: white;
    font-size: 24px;
    text-shadow: 1px 1px 1px rgba(100, 100, 100, 0.2);

}

.my-cart--title-close {
    place-self: center;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    margin-right: 10px;
    display: grid;
    place-content: center;
}

.my-cart--title-close > img {
    transition: 0.1s ease-in-out;
    filter: brightness(0%);
}

.my-cart--title-close:active > img {
    filter: brightness(100%);
    width: 27px;
    height: 27px;  
}

.my-cart--events {
    background-color: white;
}

.my-cart--events-headers {
    background-color: rgb(166, 138, 206);
    display: grid;
    grid-template-rows: 50px;
    grid-template-columns: 50px 1fr 1fr 2fr 1fr;
}

.my-cart--events-header {
    place-self: center;
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: bold;
}

.my-cart--event {
    width: 100%;
    height: 60px;
    font-family: monospace;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 50px 1fr 1fr 2fr 1fr;
    border-bottom: 3px dashed rgba(100, 100, 100, 0.2);
    transition: 0.2s;
}

.my-cart--event:hover {
    background-color: rgb(228, 240, 255)
}

.my-cart--event-delete {
    display: grid;
    place-content: center;
    place-self: center;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 2px solid rgb(240, 138, 138);
    border-radius: 8px;
    color: rgb(240, 138, 138);
    font-family: monospace;
    font-weight: bold;
    font-size: 14px;
}

.my-cart--event-delete:active {
    transition: 0.1s;
    background-color: rgb(240, 138, 138);
    color: white;
}

.my-cart--event-date {
    place-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my-cart--event-time {
    place-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my-cart--event-desc {
    place-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my-cart--event-pricing {
    place-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my-cart--submit {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 1fr 1fr 15px 1fr;
}

.my-cart--submit-status-bar {
    width: 450px;
    height: 80px;
    place-self: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
    font-weight: bold;
    color: aliceblue;
    display: grid;
    place-items: center;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    border-radius: 20px;
    background-color: rgb(117, 214, 201);
    box-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2);
    transition: 0.2s;
}

.my-cart--submit-status-bar--content {
    display: grid;
    grid-auto-flow: column;
}

.my-cart--submit-status-bar--content > img {
    place-self: center end;
    padding-right: 10px;
}

.my-cart--submit-status-bar--content > p {
    text-align: center;
    text-shadow: 1px 1px 1px rgba(100, 100, 100, 0.2);
}

.my-cart--submit-details {
    color: rgb(100, 100, 100);
    font-family: monospace;
    font-size: 14px;
    font-weight: bold;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr;
}

.my-cart--submit-details-total {
    display: grid;
    place-self: center end;
}

.my-cart--submit-details-btn {
    border: none;
    background-color: #ffc107;
    display: grid;
    place-self: center;
    place-items: center;
    place-content: center;
    border-radius: 20px;
    box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
    grid-auto-flow: column;
    grid-template-columns: 50px 90px;
    transition: 0.1s;
    height: 35px;
    overflow: hidden;
}

.my-cart--submit-details-btn:active {
    background-color: #ffe8a4;
}

.my-cart--submit-details-currency {
    font-size: 13px;
    place-self: center start;
    margin-left: 10px;
    color: rgb(80, 80, 80);
    font-family: monospace;
    text-shadow: 1px 1px 1px rgba(120, 120, 120, 0.2);
    font-weight: bold;
}

.my-cart--submit-details-pricing {
    display: grid;
    place-self: center;
    place-content: center;
    font-weight: bold;
    background-color: rgb(191, 116, 177);
    color: white;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 12px;
    padding: 0px 8px 0px 8px;
    border-radius: 20px;
    width: 90px;
    height: 35px;
    text-shadow: 1px 1px 1px rgba(120, 120, 120, 0.2);
}

.my-cart--submit-disclaimer {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    color: rgb(70, 70, 70);
    padding-left: 40px;
    padding-right: 40px;
    display: grid;
    place-items: center;
    text-align: center;
}

.my-cart--submit-checkbox {
    place-self: center;
    font-family: monospace;
    font-size: 14px;
}

.my-cart--submit-btn {
    place-self: center;
    border: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(100, 100, 100, 0.5);
    background-color: rgb(191, 116, 177);
    color: white;
    width: 100px;
    height: 40px;
    border-radius: 15px;
    box-shadow: 2px 2px 2px rgba(100, 100, 100, 0.2);
}

.my-cart--submit-btn:disabled,
.my-cart--submit-btn[disabled]{
    background-color: rgb(170, 170, 170);
}

.payment--options-body {
    place-self: center;
    margin-top: 20px;
    background-color: rgb(249, 249, 249);
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 60px 160px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 6px rgba(100, 100, 100, 0.25);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: rgb(70, 70, 70);
    width: 70%;
}

.payment--options-body > div {
    place-self: center;
}

.payment--options-method {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 2fr 1fr;
    grid-template-areas: "logo button"
    "text text";
    place-items: center;
    width: 100%;
    height: 100%;
    place-self: center;
}

.payment--options-transbank-img {
    background-image: url("../../public/webpay_plus.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: none;
    margin-left: 30px;
    width: 300px;
    height: 75px;
    grid-area: logo;
}

.option-btn {
    grid-area: button;
}

.payment--options-text {
    place-self: center;
    grid-area: text;
    font-weight: normal;
}

@media (max-width: 768px) {
    .my-cart--body {
        place-self: start center;
        width: 100%;
        max-width: 400px;
        grid-template-rows: 60px 1fr 300px;
    }

    .my-cart--title {
        font-size: 22px;
        max-width: 400px;
    }

    .my-cart--title-text {
        margin-left: 30px;
    }

    .my-cart--title-seal {
        margin-left: 10px;
        width: 30px;
        height: 30px;
        font-size: 18px;
    }

    .my-cart--title-close > img {
        width: 25px;
        height: 25px;
    }

    .my-cart--title-close:active > img {
        width: 22px;
        height: 22px;  
    }

    .my-cart--events {
        max-width: 400px;
    }

    .my-cart--events-headers {
        max-width: 400px;
        grid-template-rows: 40px;
        grid-template-columns: 40px 1fr 1fr 2fr 1fr;
    }

    .my-cart--events-header {
        font-size: 11px;
    }

    .my-cart--event {
        width: 100%;
        height: 40px;
        font-size: 10px;
        grid-template-rows: 1fr;
        grid-template-columns: 40px 1fr 1fr 2fr 1fr;
        border-bottom: 2px dashed rgba(100, 100, 100, 0.2);
    }

    .my-cart--event-delete {
        width: 25px;
        height: 25px;
        border: 2px solid rgb(240, 138, 138);
        font-size: 11px;
    }

    .my-cart--submit-details {
        grid-template-columns: 3fr 2fr;
    }

    .my-cart--submit-details-total {
        font-size: 12px;
    }

    .my-cart--submit-details-btn {
        height: 30px;
        grid-template-columns: 40px 70px;
        border-radius: 15px;
    }

    .my-cart--submit-details-currency {
        font-size: 11px;
        place-self: center start;
        margin-left: 7px;
    }

    .my-cart--submit-details-pricing {
        font-size: 10px;
        padding: 0px 8px 0px 8px;
        border-radius: 15px;
        height: 30px;
        width: 70px;
    }

    .my-cart--submit-status-bar {
        width: 85%;
        height: 60px;
        font-size: 10px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .my-cart--submit-status-bar--content > img {
        width: 30px;
        height: 30px;
        place-self: center end;
        padding-right: 5px;
    }

    .my-cart--submit-disclaimer {
        font-size: 11px;
        text-align: center;
    }

    .my-cart--submit-checkbox {
        font-size: 12px;
    }

    .my-cart--submit-btn {
        font-size: 12px;
        width: 90px;
        height: 35px;
        border-radius: 15px;
    }

    .payment--options-body {
        grid-template-rows: 50px 120px;
        font-size: 11px;
        width: 100%;
        max-width: 400px;
    }

    .payment--options-transbank-img {
        width: 200px;
        height: 50px;
        margin-left: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}