.admin-buttons {
    place-self: center;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: 1fr 1fr;
    row-gap: 5px;
    grid-template-areas: "edit assign"
    "edit delete";
}

.admin-buttons--edit {
    place-self: center;
    place-content: center;
    display: grid;
    place-items: center;
    text-align: center;
    grid-area: edit;
    border: none;
    background-color: transparent;
    background-image: url("../../public/edit.svg");
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 5px;
    background-size: 40px;
    width: 40px;
    height: 40px;
}

.admin-buttons--assign {
    place-self: center;
    place-content: center;
    display: grid;
    place-items: center;
    text-align: center;
    grid-area: assign;
    background: linear-gradient(90deg, rgb(138, 182, 243), rgb(166, 138, 206));
    border: none;
    color: white;
    border-radius: 10px;
    width: 30px;
    height: 22px;
    box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
}

.admin-buttons--delete {
    place-self: center;
    place-content: center;
    display: grid;
    place-items: center;
    text-align: center;
    grid-area: delete;
    background-color: rgb(240, 138, 138);
    border: none;
    color: white;
    border-radius: 10px;
    width: 30px;
    height: 22px;
    box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
}

.message-mode {
    place-self: center;
    display: grid;
    place-items: center;
    text-align: center;
    font-size: 10px;
}

.message-mode--btn {
    display: grid;
    place-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 10px;
    border-radius: 10px;
    border: 2px solid rgb(220, 220, 220);
    background-color: transparent;
    color: rgb(120, 120, 120);
    padding: 3px 8px 3px 8px;
}

.delete-mode {
    display: grid;
    width: 80%;
    height: 70%;
    place-self: center;
    place-items: center;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
}

.delete-mode--confirm {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 10px;
    border-radius: 10px;
    border: 2px solid rgb(240, 138, 138);
    background-color: rgb(240, 138, 138);
    color: white;
    padding: 3px 8px 3px 8px;
}

.delete-mode--cancel {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 10px;
    border-radius: 10px;
    border: 2px solid rgb(220, 220, 220);
    background-color: transparent;
    color: rgb(120, 120, 120);
    padding: 3px 8px 3px 8px;
}

.assign-mode {
    display: grid;
    width: 80%;
    height: 70%;
    place-self: center;
    place-items: center;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
}

.assign-mode--email {
    display: grid;
    width: 95%;
    height: 70%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 10px;
    border-radius: 10px;
    border: 1.5px solid rgb(120, 120, 120);
    text-align: center;
}

.assign-mode--buttons {
    display: grid;
    place-self: center;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    width: 100%;
}

.assign-mode--buttons-cancel {
    display: grid;
    place-items: center;
    place-content: center;
    border: 2px solid rgb(220, 220, 220);
    background-color: transparent;
    color: rgb(120, 120, 120);
    border-radius: 10px;
    width: 30px;
    height: 22px;
    box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
}

.assign-mode--buttons-assign {
    display: grid;
    place-items: center;
    place-content: center;
    background: linear-gradient(90deg, rgb(138, 182, 243), rgb(166, 138, 206));
    border: none;
    color: white;
    border-radius: 10px;
    width: 30px;
    height: 22px;
    box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
}

.edit-mode {
    place-self: center;
}

@media (max-width: 768px) {
    .admin-buttons--edit {
        border-radius: 5px;
        background-size: 30px;
        width: 35px;
        height: 38px;
    }

    .admin-buttons--assign {
        border-radius: 10px;
        font-size: 12px;
        width: 25px;
        height: 18px;
        box-shadow: 1px 1px 1px rgba(120, 120, 120, 0.2);
    }

    .admin-buttons--delete {
        border-radius: 10px;
        font-size: 12px;
        width: 25px;
        height: 18px;
        box-shadow: 1px 1px 1px rgba(120, 120, 120, 0.2);
    }

    .assign-mode--buttons-cancel {
        border-radius: 10px;
        width: 25px;
        height: 18px;
        box-shadow: 1px 1px 1px rgba(120, 120, 120, 0.2);
    }
    
    .assign-mode--buttons-assign {
        border-radius: 10px;
        width: 25px;
        height: 18px;
        box-shadow: 1px 1px 1px rgba(120, 120, 120, 0.2);
    }

}