.about--header {
    display: grid;
    grid-auto-flow: column;
    text-align: center;
    place-content: center;
    place-items: center;
    vertical-align: middle;
    font-family: debussy;
    font-size: 54px;
    background-color: rgb(166, 138, 206);
    color: white;
    text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.3);
}

.about--container {
    padding-top: 30px;
    padding-bottom: 20px;
    display: grid;
}

.div--about {
    place-self: start center;
    display: grid;
    width: 70%;
    height: calc(100dvh - 270px);
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 4fr 1fr;
    row-gap: 1px;
    grid-template-areas: 
    "bio links"
    "website website";
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
}

.box-about-style {
    border-radius: 3px;
    transition: 0.2s;
}

.div--about-bio {
    place-self: center;
    grid-area: bio;
    display: grid;
    overflow: hidden;
    background-color: transparent;
    padding: 20px 0px 0px 0px;
    border-radius: 15px;
    overflow: auto;
    border: none;
    box-shadow: none;
    background-color: transparent;
}

.div--about-bio--title {
    display: grid;
    place-items: center;
    width: 12rem;
    height: 3rem;
    font-size: 1.5rem;
    background: linear-gradient(90deg, rgb(138, 182, 243), rgb(166, 138, 206));
    position: fixed;
    z-index: 2;
    top: 0px;
    border-radius: 1.5rem;
    overflow: hidden;
    color: white;
    text-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2);
    box-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2);
}

.div--about-bio--text {
    width: 100%;
    height: 95%;
    background-color: transparent;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: grid;
    row-gap: 10px;
    grid-auto-rows: 1fr;
    place-items: center;
    text-align: justify;
    font-size: 16px;
    line-height: 1.5;
    color: rgb(80, 80, 80);
    text-shadow: 1px 1px 1px rgba(120, 120, 120, 0.2);
    padding-top: 5px;
}

.content--row {
    place-self: center;
    place-items: center;
    width: 95%;
    height: 100px;
    padding: 3px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 15px 30px 30px 20px;
    grid-template-areas: "pic title"
    "pic title"
    "pic title"
    "pic date";
    border: none;
    border-radius: 30px 30px 30px 0px;
    color: rgb(100, 100, 100);
    background-color: transparent;
}

.content--popup {
    display: grid;
    place-self: center;
    place-items: center;
    place-content: center;
    grid-auto-flow: row;
}

.content--popup--news {
    display: grid;
    place-self: center;
    place-items: center;
    place-content: center;
    padding: 10px 30px 10px 30px;
    grid-auto-flow: row;
    grid-template-rows: 20px 1fr;
    row-gap: 10px;
    font-size: 16px;
    line-height: 1.5;
    text-align: justify;
}

.content--popup--news span + span {
    margin-top: 10px;
}

.content--date-top {
    display: grid;
    place-items: center;
    place-content: center;
    font-family: monospace;
    font-size: 12px;
    font-weight: bold;
    color: rgb(180, 180, 180);
    text-shadow: none;
}

.content--pic {
    grid-area: pic;
    place-self: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    background-image: url("../../public/kitty_face.png");
    background-size: 80px;
}

.content--title {
    place-self: center;
    display: block;
    place-content: center;
    place-items: center;
    grid-area: title;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    text-align: center;
    width: 80%;
    height: 100%;
    box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
    border-radius: 30px 30px 30px 0px;
    border: 2px solid rgb(138, 182, 243);
    overflow: auto;
    padding: 0px 20px 0px 20px;
}

.content--title:hover {
    transition: 0.2s;
    background-color: rgb(228, 240, 255);
}

.content--date {
    display: grid;
    place-items: center;
    grid-area: date;
    font-family: monospace;
    font-size: 12px;
    font-weight: bold;
    color: rgb(180, 180, 180);
    text-shadow: none;
}

.content--content {
    place-self: start center;
    display: grid;
    place-items: center;
    grid-area: content;
    overflow: hidden;
    width: 90%;
    font-size: 15px;
}

.div--about-media {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    overflow: hidden;
    row-gap: 20px;
    column-gap: 20px;
}

.div--about-media--label {
    place-self: center;
    font-size: 22px;
    color: rgb(150, 150, 150);
}

.div--about-media--label-2 {
    display: none;
    place-self: center;
    font-size: 22px;
    color: rgb(150, 150, 150);
}

.div--about-media > a {
    place-self: center;
    display: grid;
    place-items: center;
}

.div--about-media > a img {
    width: 35px;
    height: 35px;
}

.div--about-other {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    font-size: 20px;
    grid-template-rows: 1fr;
}

.div--about-other--content {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    row-gap: 20px;
}

.div--about-other--filler-btn {
    width: 100%;
    height: 50px;
    border: 1px solid rgb(240, 240, 240);
    background-color: white;
    border-radius: 15px;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(120, 120, 120);
    box-shadow: 2px 2px 4px rgba(120, 120, 120, 0.2);
    text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
    transition: 0.1s;
}

.div--about-other--filler-btn:hover {
    background-color: rgb(191, 116, 177);
    color: white;
    border: none;
}

.div--about-other--filler-btn:focus {
    background-color: rgb(191, 116, 177);
    color: white;
    border: none;
}

.filler-btn--highlight {
    background-color: rgb(191, 116, 177);
    color: white;
    border: none;
}

.filler-btn--highlight:hover {
    background-color: white;
    color: rgb(191, 116, 177);
    border: 3px solid rgb(191, 116, 177);
}

@keyframes rotate {
    0% {
        transform: rotateX(0);
    }
    50% {
        transform: rotateX(3.142rad);
    }
    100% {
        transform: rotateX(0);
    }
  }

.rotation--animation {
    animation: 0.5s both 0.5s rotate;
}

@keyframes Jump {
    0%, 100% {
        text-shadow: 0px 0px 0px rgb(120, 120, 120);
        color: aliceblue;
    }
    20% {
        color: white;
        text-shadow: 0px 0px 10px white;
    }
}

.jump-1 {
    animation-name: Jump;
    animation-duration: 0.3s;
}

.div--about-links {
    grid-area: links;
    display: grid;
    place-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    border-color: rgb(240, 240, 240);
    border-left-width: 2px;
    border-left-style: solid;
}

.div--about-website {
    grid-area: website;
    background-color: transparent;
    color: rgb(100, 100, 100);
    display: grid;
    grid-template-rows: 1fr;
    place-items: center;
    overflow: hidden;
}

.div--about-website-btn {
    display: grid;
    place-items: center;
    grid-auto-flow: row;
    border: none;
    border-radius: 30px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: 16px;
    background-color: rgb(166, 138, 206);
    color: white;
    text-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
    box-shadow: 2px 2px 4px rgba(120, 120, 120, 0.2);
    width: 120px;
    height: 120px;
    transition: 0.2s;
    place-content: center;
}

.div--about-website-btn:hover {
    background-color: rgb(191, 116, 177);
}

.div--about-website-btn:focus {
    background-color: rgb(191, 116, 177);
}

.website-btn--scheduler-icon {
    background-image: url("../../public/calendar.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 34px;
    width: 34px;
    height: 34px;
}

.website-btn--text {
    place-self: center start;
}

@media (max-width: 768px) {

    .about--header {
        font-size: 32px;
    }

    .about--container {
        width: 350px;
        place-self: start center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .div--about {
        width: 100%;
        height: calc(100dvh - 200px);
        padding-left: 5px;
        padding-right: 5px;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
        "bio"
        "links"
        "website";
        row-gap: 0px;
    }

    .div--about-bio {
        width: 100%;
        padding: 20px 0px 0px 0px;
        border-radius: 10px;
        box-shadow: none;
    }

    .div--about-bio--title {
        width: 120px;
        height: 35px;
        font-size: 1rem;
        top: -10px;
    }

    .div--about-bio--text {
        height: 100%;
        font-size: 11px;
        line-height: 1.5;
        row-gap: 3px;
        grid-auto-rows: 1fr;
        padding-top: 0px;
    }

    .content--row {
        width: 95%;
        height: 70px;
        padding: 2px 5px 2px 5px;
        grid-template-rows: 10px 40px 20px;
        grid-template-columns: 80px 1fr;
        grid-template-areas: "pic title"
        "pic title"
        "pic date";
        border-radius: 20px 20px 20px 0px;
        column-gap: 5px;
        overflow: hidden;
    }

    .content--popup--news {
        padding: 4px 20px 20px 20px;
        grid-template-rows: 20px 1fr;
        row-gap: 10px;
        font-size: 14px;
        line-height: 1.4;
    }

    .content--popup--news span + span {
        margin-top: 5px;
    }

    .content--date-top {
        font-size: 10px;
    }

    .content--pic {
        width: 60px;
        height: 60px;
        background-size: 60px;
    }

    .content--title {
        font-size: 12px;
        box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
        border-radius: 20px 20px 20px 0px;
        border: 1.5px solid rgb(138, 182, 243);
        width: 80%;
        height: 100%;
        font-weight: normal;
        text-shadow: none;
        font-family: Arial, Helvetica, sans-serif;
        padding: 0px 10px 0px 10px;
    }

    .content--date {
        font-size: 10px;
    }

    .content--content {
        display: none;
    }

    .div--about-media {
        place-self: center;
        width: 95%;
        height: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
        "label label label label"
        "instagram tiktok linkedin buymeacoffee"
        "label2 label2 label2 label2";
        row-gap: 0px;
        column-gap: 0px;
    }

    .div--about-media--label {
        grid-area: label;
        place-self: center;
        font-size: 20px;
    }

    .div--about-media--label-2 {
        display: grid;
        grid-area: label2;
        place-self: center;
        font-size: 20px;
    }

    .div--about-media > a {
        place-self: center;
    }

    .div--about-media > a img {
        width: 45px;
        height: 45px;
    }

    .div--about-media-account {
        font-size: 15px;
    }

    .div--about-other {
        grid-template-rows: 1fr;
        border-radius: 15px;
    }

    .div--about-other--content {
        row-gap: 15px;
    }

    .div--about-other--filler-btn {
        place-self: center;
        width: 95%;
        height: 40px;
        border: 1px solid rgb(240, 240, 240);
        border-radius: 15px;
        font-size: 11px;
        box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
    }

    .filler-btn--highlight {
        border: 2px solid rgb(191, 116, 177);
    }

    .div--about-links {
        border-color: rgb(240, 240, 240);
        border-top-width: 1px;
        border-top-style: solid;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-left-width: 0px;
        overflow: hidden;
    }

    .div--about-website {
        grid-template-rows: 1fr;
        place-items: center;
    }

    .div--about-website-btn {
        width: 80px;
        height: 80px;
        font-size: 11px;
        border-radius: 20px;
        box-shadow: 1px 1px 2px rgba(120, 120, 120, 0.2);
        min-width: 80px;
        grid-auto-flow: row;
        place-content: center;
        row-gap: 5px;
    }

    .website-btn--scheduler-icon {
        background-size: 35px;
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 768px) and (max-height: 800px) {
    .div--about {
        grid-template-rows: 3fr 2fr 2fr;
    }

    .div--about-bio {
        max-height: 90%;
        overflow: auto;
        padding: 10px 0px 0px 0px;
    }

    .div--about-other--content {
        row-gap: 2px;
    }

    .div--about-other--filler-btn {
        height: 35px;
        border-radius: 15px;
    }

    .div--about-media--label {
        grid-area: label;
        place-self: center;
        font-size: 16px;
    }

    .div--about-media--label-2 {
        display: grid;
        grid-area: label2;
        place-self: center;
        font-size: 16px;
    }

    .website-btn--scheduler-icon {
        background-size: 35px;
        width: 35px;
        height: 35px;
    }

    .div--about-media > a img {
        width: 38px;
        height: 38px;
    }
}