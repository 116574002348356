.add-event--popup-window-body {
    display: grid;
    background-color: white;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    height: 100%;
    width: 75%;
    max-height: 200px;
}

.add-event--popup-window-body > input {
    border: 1px solid rgb(138, 182, 243);
    border-radius: 10px;
}

.add-event--popup-window-body > select {
    border: 1px solid rgb(138, 182, 243);
    border-radius: 10px;
}

.add-event--status {
    display: grid;
    place-self: center;
    place-items: center;
    place-content: center;
    font-family: monospace;
    font-size: 12px;
    overflow: hidden;
    max-width: 100%;
    text-align: center;
}

.add-event--submit-btn {
    place-self: center;
    place-content: center;
    place-items: center;
    border: none;
    border-radius: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    text-shadow: 1px 1px 1px rgba(100, 100, 100, 0.5);
    font-size: 16px;
    color: white;
    padding: 8px;
    width: 120px;
    background-color: rgb(191, 116, 177);
    box-shadow: 0px 0px 6px rgba(150, 150, 150, 0.5);
    text-shadow: 1px 1px 2px rgba(150, 150, 150, 0.3);
}

@media (max-width: 768px) {
    .add-event--popup-window-body {
        padding-top: 0px;
        grid-template-rows: 30px 30px 30px 30px 30px;
        height: 100%;
        width: 75%;
        max-height: 160px;
    }

    .add-event--popup-window-body > input {
        font-size: 12px;
    }

    .add-event--popup-window-body > select {
        font-size: 12px;
    }

    .add-event--status {
        font-size: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .add-event--submit-btn {
        font-size: 10px;
        padding: 7px;
        width: 90px;
    }

}