.clock--container {
    place-self: center end;
    display: grid;
    grid-auto-flow: column;
    place-items: center;
    width: 160px;
    height: 40px;
    color: rgb(248, 248, 248);
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin-right: 40px;
    text-shadow: 1px 1px 1px rgba(100, 100, 100, 0.2);
}

.clock--time {
    display: grid;
    place-items: center;
    width: 50px;
    padding: 3px 5px 3px 5px;
    grid-auto-flow: column;
    font-family: monospace;
    border-radius: 15px;
    background-color: rgb(248, 248, 248);
    color: rgb(110, 207, 198);
    box-shadow: 2px 2px 3px rgba(150, 150, 150, 0.2);
    text-shadow: none;
}

@media (max-width: 768px) {
    .clock--container {
        width: 110px;
        height: 30px;
        font-size: 11px;
        margin-right: 20px;
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .clock--time {
        font-size: 10px;
        width: 30px;
        padding: 2px 5px 2px 5px;
        border-radius: 10px;
    }

}